import {
  ACTIONS,
  activity,
  AnalyticsEventPropTypes,
  eventDetailVersion
} from '@hpx-core-experiences/react-my-account-commons/dist';
import { screenPath, screenName } from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';

export const HpStoreWalletButtonClicked: AnalyticsEventPropTypes = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'HpStoreWalletLink',
  controlDetail: URLS.HpStoreWallet,
  version: eventDetailVersion
};
